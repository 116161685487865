<template>
  <div class="integral">
    <back></back>
    <div class="text-right">
     <span class="f-12 my-stone">我的元石：{{user.stone}}</span>
    </div>
    <div class="content">
      <div class="box" v-for="item in list" :key="item.id">
        <div class="up">
          <img :src="item.image" class="cover" width="100%" height="100%"/>
          <div class="f-10">数量：{{item.max}}</div>
        </div>
        <div class="down">
          <div>
            <div class="f-12 m-b-5">{{item.name}}</div>
            <div class="f-12" :class="user.stone < item.store ? 'red' : ''">{{item.store}}元石</div>
          </div>
          <div class="btn f-15 bg-black" @click="showConvert(item.id)">兑换</div>
        </div>
      </div>
<!--      <div class="box">-->
<!--        <div class="up">-->
<!--          <div class="f-10">数量：不限</div>-->
<!--        </div>-->
<!--        <div class="down">-->
<!--          <div>-->
<!--            <div class="f-12 m-b-5">轻松宝箱</div>-->
<!--            <div class="f-12 red">3000元石</div>-->
<!--          </div>-->
<!--          <div class="btn f-15 bg-grey">兑换</div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="box filter">-->
<!--        <div class="up">-->
<!--          <div class="f-10">数量：不限</div>-->
<!--        </div>-->
<!--        <div class="down">-->
<!--          <div>-->
<!--            <div class="f-12 m-b-5">轻松宝箱</div>-->
<!--            <div class="f-12">3000元石</div>-->
<!--          </div>-->
<!--          <div class="btn f-15 bg-grey">已兑换</div>-->
<!--        </div>-->
<!--      </div>-->
    </div>
    <van-overlay :show="isShowBox" @click="isShowBox = false">
      <div class="wrapper box">
        <div class="block">
<!--          <div class="close">-->
<!--            <van-icon name="cross" size="15" color="#69686D" />-->
<!--          </div>-->
          <div class="m-b-20 f-22 bold">兑换成功</div>
          <div class="btn" @click="isShowBox = false">关闭</div>
        </div>
      </div>
    </van-overlay>
    <van-overlay :show="isShow" @click="isShow = false">
      <div class="wrapper" @click.stop>
        <div class="confirm-box">
          <div class="f-18 m-b-25">确认兑换盲盒吗？</div>
          <div class="btn-group">
            <div class="cancel" @click="isShow = false">取消</div>
            <div class="confirm" @click="convert">确定</div>
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { goodsApi, convertApi, userApi } from '../api/user'
import Back from '../components/Back'
import { getWxConfig } from '../../tool'

export default {
  name: 'Integral',
  components: { Back },
  data () {
    return {
      isShowBox: false,
      list: [],
      isShow: false,
      id: 0
    }
  },
  computed: {
    ...mapState({
      user: state => state.user
    })
  },
  created () {
    this.getList()
    getWxConfig('轻松小镇', '兑换盲盒')
  },
  activated () {
    this.getList()
  },
  methods: {
    async getList () {
      try {
        const res = await goodsApi({})
        this.list = res.data.mall
        console.log(res)
      } catch (e) {
        console.log(e)
      }
    },
    showConvert (id) {
      this.id = id
      this.isShow = true
    },
    async convert (id) {
      try {
        await convertApi({ mall_id: this.id })
        this.getUser()
        this.isShow = false
        this.isShowBox = true
      } catch (e) {
        console.log(e)
        this.isShow = false
        this.$toast.fail(e.data)
      }
    },
    ...mapMutations({
      vxSetUser: 'setUser'
    }),
    async getUser () {
      try {
        const res = await userApi({})
        this.vxSetUser({
          isSign: res.data.signs,
          ...res.data.user
        })
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.integral{
  padding: 18px;
  background-color: #F7F7F7;
  min-height: 100vh;
  .my-stone{
    padding: 5px 10px;
    background: linear-gradient(90deg, #E4E9F2, #E5E7F0);
    border: 1px solid #FFFFFF;
    border-radius: 15px;
  }
  .content{
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
    .box{
      background-color: white;
      width: calc(50% - 10px);
      margin-right: 20px;
      margin-bottom: 15px;
      border-radius: 7px;
      overflow: hidden;
      .up{
        width: 160px;
        height: 160px;
        background-size: cover;
        padding: 12px 9px;
        position: relative;
        .cover{
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          display: block;
        }
      }
      .down{
        padding: 13px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .btn{
          padding: 5px 15px;
          color: white;
          border-radius: 15px;
        }
        .bg-black{
          background-color: black;
        }
        .bg-grey{
          background-color: #A2A2A2;
        }
        .red{
          color: #C30808;
        }
      }
      &:nth-child(2n){
        margin-right: 0;
      }
    }
    .filter{
      filter: grayscale(1)
    }
  }
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    .block {
      width: 75%;
      background-color: #fff;
      border-radius: 10px;
      position: relative;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      padding: 50px 30px;
      .close{
        position: absolute;
        width: 27px;
        height: 27px;
        border-radius: 50%;
        background: #ECECEC;
        right: 10px;
        top: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .btn{
        font-size: 15px;
        border: 1px solid #000000;
        padding: 8px 15px;
        border-radius: 20px;
      }
    }
    .confirm-box {
      width: 80%;
      padding: 35px 20px 20px;
      background: #FFFFFF;
      border-radius: 10px;
      .btn-group{
        display: flex;
        justify-content: space-between;
        padding: 0 15px;
        .cancel{
          width: 104px;
          height: 38px;
          border: 1px solid #000000;
          border-radius: 19px;
          line-height: 38px;
          text-align: center;
        }
        .confirm{
          width: 104px;
          height: 38px;
          background: #000000;
          border: 1px solid #000000;
          border-radius: 19px;
          line-height: 38px;
          color: white;
          text-align: center;
        }
      }
    }
  }
}
</style>
